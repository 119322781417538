import React from "react"

import { Layout, SEO } from "../components/shared"
import { Skills } from "../components/Skill/Skills"

const SkillsPage = (props) => (
  <>
    <Layout locationPath={props.location.pathname}>
        <SEO title="Skills | React | Node | MongoDB | Express | Vanilla | ES6 | mySQL | Frontend | Backend | |mdmostafa.com" />
        <Skills />
      </Layout>
  </>
)

export default SkillsPage;