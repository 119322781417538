import React from 'react';
import { Container } from '../shared';

const Skills = () => {
    console.log("skills page")
    return (
        <Container>
            <h2 style={{ textAlign: 'center', margin: '0 auto'}}>Skills will be updated soon...</h2>
        </Container>
    );
};

export {Skills};